<template>
  <button type="button"
    class="w-full py-2 border-1 hover:bg-green-primary"
    :class="checkValue() ? 'border-green-primary': 'border-gray-200'"
    @click="changeValue()">
    {{ text }}
  </button>
</template>

<script>
import { pageService } from '@/properties/views/components/PropertyRegistration/stores/pageData'

export default {
  setup() {
    const page = pageService();

    return { page }
  },
  emits: ["changeValue"],
  props: {
    text: String,
    reference: undefined
  },
  methods: {
    checkValue() {
      return this.reference === true;
    },
    changeValue() {
      if (this.page.isEditable()) {
        this.$emit('changeValue');
      }
    }
  }
};
</script>