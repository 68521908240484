<template>
  <input v-if="!isNumber"
        type="text"
        class="wwnw-text-box lg:col-span-2 focus:outline-none"
        :placeholder="placeholderTxt"
        @change="changeValue($event)"
        :readonly="!page.isEditable()">
  <input v-else
        type="number"
        class="wwnw-text-box lg:col-span-2 focus:outline-none"
        :placeholder="placeholderTxt"
        pattern="\d*"
        @wheel="$event.target.blur()"
        min="0"
        oninput="validity.valid||(value='');"
        @change="changeValue($event)"
        :readonly="!page.isEditable()">
</template>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>

<script>
import { pageService } from '@/properties/views/components/PropertyRegistration/stores/pageData'

export default {
  setup() {
    const page = pageService();

    return { page }
  },
  emits: ["changeValue"],
  props: {
    placeholderTxt: String,
    isNumber: Boolean
  },
  methods: {
    changeValue(event) {
      this.$emit('changeValue', event.target.value);
    }
  }
};
</script>